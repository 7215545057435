import React, { useContext } from 'react';
import { Modal } from '../../Modal/Modal';
import { ModalsContext } from '../../../context/modal.context';
import { FormAddDevice } from './components/FormAddDevice/FormAddDevice';
import style from './AddDeviceModal.module.scss';
import { CloseButton } from '../../CloseButton/CloseButton';

export const AddDeviceModal: React.FC = () => {
  const { isModalVisible, clearModals } = useContext(ModalsContext);

  return (
    <Modal
      name="add-device-data"
      open={isModalVisible('add-device-data')}
      className={`.and-modal-wrap .ant-modal `}
      style={{ fontWeight: 'bold' }}
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ style: { display: 'none' } }}
      footer={null}
      closable={false}
    >
      <div className={style.h1Wrapper}>
        <h1> Add Device</h1>
        <CloseButton onClick={() => clearModals()} />
      </div>
      <div className={style.main}>
        <FormAddDevice />
      </div>
    </Modal>
  );
};
