import React, { useContext } from 'react';
import Sidebar from 'react-sidebar';
import { SidebarComponent } from './components/SidebarComponent/SidebarComponent';
import { ParkingAppContext } from '../../../../../context/parking.context';

interface ISidebarProps {
  children: React.ReactNode;
}

const SideBar: React.FC<ISidebarProps> = ({ children }) => {
  const { sideBarVisible } = useContext(ParkingAppContext);

  return (
    <Sidebar
      styles={{ sidebar: { overflowY: 'none', backgroundColor: '#162b4a', width: '300px' } }}
      sidebar={<SidebarComponent />}
      open={sideBarVisible}
      pullRight={true}
    >
      {children}
    </Sidebar>
  );
};

export default SideBar;
