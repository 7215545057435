import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import style from './LoginButton.module.scss';

export const LoginButton = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();

  return (
    <>
      {!isAuthenticated && (
        <>
          <button
            className={style.button}
            onClick={() => {
              loginWithRedirect();
            }}
          >
            Log In
          </button>
        </>
      )}
    </>
  );
};
