import { GoogleMap, Marker } from '@react-google-maps/api';
import { ParkingAppContext } from '../../../../../../../context/parking.context';
import { useContext } from 'react';
import style from './Map.module.scss';
import { InfoParkingModal } from '../InfoParkingModal/InfoParkingModal';
import { ModalsContext } from '../../../../../../../context/modal.context';
import { IParkingPlaces } from '../../../../../../../modules/parking/parking.types';

const Map = () => {
  const { handleUpdateModals } = useContext(ModalsContext);
  const { isInfoParkingVisible, parkingList, parkingPlace, setParkingAppStateHandler } = useContext(ParkingAppContext);

  const center: any = {
    lat: parkingPlace.latitude ? parkingPlace.latitude : 44.8125,
    lng: parkingPlace.longitude ? parkingPlace.longitude : 20.4612,
  };

  const openParkingModal = (place: any) => {
    handleUpdateModals({ modalVisible: 'InfoParkingModal' });
    setParkingAppStateHandler({ parkingPlace: place, isInfoParkingVisible: true });
  };

  return (
    <div className={`${style.container}`}>
      {isInfoParkingVisible && <InfoParkingModal />}

      <GoogleMap
        center={center}
        zoom={15}
        mapContainerStyle={{ width: '100%', borderRadius: '20px' }}
        options={{ zoomControl: true, streetViewControl: false, mapTypeControl: false, fullscreenControl: false }}
      >
        {parkingList &&
          parkingList.map((place: IParkingPlaces, index: number) => (
            <Marker
              key={index}
              onClick={() => openParkingModal(place)}
              position={{ lat: place.latitude as number, lng: place.longitude as number }}
            />
          ))}
      </GoogleMap>
    </div>
  );
};

export default Map;
