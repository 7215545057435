import React from 'react';
import { ISidebarMenu } from '../../../../../../../../modules/parking/parking.types';
import NavListItem from './NavListItem/NavListItem';
import style from './NavList.module.scss';
interface ISidebarMenuProps {
  sidebarmenu: ISidebarMenu[];
}

export const NavList: React.FC<ISidebarMenuProps> = props => {
  return (
    <div className={style.nav}>
      {props.sidebarmenu.map((item, index) => {
        return <NavListItem key={index} navItem={item} />;
      })}
    </div>
  );
};
