import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '../Button/Button';

export const LogoutButton = () => {
  const { logout, isAuthenticated } = useAuth0();

  return (
    <>
      {isAuthenticated && (
        <Button
          name="logout"
          style={{ fontSize: '16px', display: 'flex' }}
          onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
        >
          Log Out
        </Button>
      )}
    </>
  );
};
