import React from 'react';
import style from './InfoUser.module.scss';
import { useAuth0 } from '@auth0/auth0-react';

export const InfoUser: React.FC = () => {
  const { user, isAuthenticated } = useAuth0();
  console.log(user);
  return (
    <>
      {isAuthenticated && user !== undefined && (
        <div className={style.wrapperProfile}>
          <div className={style.picture}>
            <img className={style.profileImage} src={user.picture} />
          </div>
          <div className={style.label}>
            <div>{user.name}</div>
          </div>
        </div>
      )}
    </>
  );
};
