import style from './FormUserData.module.scss';
import { Button, Form } from 'antd';
import React, { useContext, useState } from 'react';
import { FormInput } from '../../../../FormInput/FormInput';
import { hostService } from '../../../../../modules/host/host.service';
import { ModalsContext } from '../../../../../context/modal.context';
import { toast } from 'react-toastify';
import { ParkingAppContext } from '../../../../../context/parking.context';

const INITIAL_USER_DATA = {
  firstName: '',
  lastName: '',
  email: '',
  bankAccount: '',
};

export const FormUserData: React.FC = () => {
  const [form] = Form.useForm();
  const { clearModals } = useContext(ModalsContext);
  const { setParkingAppStateHandler } = useContext(ParkingAppContext);
  const [submitButtonType, setSubmitButtonType] = useState('');
  console.log(submitButtonType);

  const onSubmit = async (values: any) => {
    const payload = {
      ...values,
    };

    toast.success('You have successfully add user data');
    console.log(payload);

    setParkingAppStateHandler({ host_data: payload });

    const fetchData = async () => {
      try {
        const res = await hostService.postHostData(payload);
        console.log(res);
      } catch (error) {
        // Handle error
        console.error(error);
      }
    };

    fetchData();

    clearModals();
    form.resetFields();
  };

  return (
    <Form
      form={form}
      className={style.form}
      name="user-data-form"
      initialValues={INITIAL_USER_DATA}
      onFinish={(values: any) => onSubmit(values)}
      layout="vertical"
    >
      <div className={style.formInput}>
        <div className={style.left}>
          <FormInput
            placeholder="First Name"
            name="firstName"
            rules={[{ required: true }]}
            label="First Name"
            inputName="firstName"
            type="text"
          />
          <FormInput
            placeholder="Last Name"
            rules={[{ required: true }]}
            name="lastName"
            label="Last Name"
            inputName="lastName"
            type="text"
          />
          <FormInput
            placeholder="Email"
            rules={[{ required: true }]}
            name="email"
            label="Email"
            inputName="email"
            type="text"
          />
          <FormInput
            placeholder="Bank account"
            rules={[{ required: true }]}
            name="bankAccount"
            label="Bank account"
            inputName="bankAccount"
            type="text"
          />
        </div>
      </div>
      <Form.Item className={style.button}>
        <Button htmlType="submit" onClick={() => setSubmitButtonType('submit')}>
          Add User Data
        </Button>
      </Form.Item>
    </Form>
  );
};
