import React from 'react';

import { Select as ANTSelect, SelectProps } from 'antd';
import { OptionProps } from 'antd/lib/select';

interface ISelectProps extends SelectProps {
  name: string;
}

export const Select: React.FC<ISelectProps> = props => {
  return <ANTSelect {...props} data-test={`select-${props.name}`} />;
};

export const SelectOption: React.FC<OptionProps> = props => {
  return <ANTSelect.Option {...props} data-test={`selectOption-${props.label}`} />;
};
