import { useContext } from 'react';
import { ParkingAppContext } from '../../context/parking.context';

const Payment: React.FC = () => {
  const { token } = useContext(ParkingAppContext);
  window.location.replace(`https://parkpilot.ai/api/payment?token=${token}`);

  return <div></div>;
};

export default Payment;
