export const durationSelectOptions = [
  {
    value: 'short',
    label: 'Short term (less than one hour)',
  },
  {
    value: 'mid',
    label: 'Mid term (between 1 and 4hours)',
  },
  {
    value: 'long',
    label: 'Long term (more than four hours)',
  },
];

export const weekday = ['Saturday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
