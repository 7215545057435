import React from 'react';
import { IParkingPlaces } from '../../../../../../../../modules/parking/parking.types';
import style from './HostListItem.module.scss';
// import { DeleteIcon } from '../../../../../../../../assets/icons/DeleteIcon/DeleteIcon';
// import { Button } from '../../../../../../../../components/Button/Button';
// import to from 'await-to-js';
// import { hostService } from '../../../../../../../../modules/host/host.service';
// import { ParkingAppContext } from '../../../../../../../../context/parking.context';
// import { Modal } from 'antd';
// import { ExclamationCircleOutlined } from '@ant-design/icons';
// import { toast } from 'react-toastify';
// import { Input } from '../../../../../../../../components/Input/Input';
import { FormNewParking } from '../../../../../../../../components/Modals/NewParkingPlaceModal/components/FormNewParking/FormNewParking';

interface HostItemProps {
  place: IParkingPlaces;
  index: number;
}

export const HostListItem: React.FC<HostItemProps> = props => {
  return (
    <div className={style.wrapper}>
      <FormNewParking data={true} place={props.place} />
    </div>
  );
};
