import { ApiResponse, axios } from '../../utils/axios';

import {
  IParkingPlaces,
  IFormAddressToGo,
  IRequestParkingPlace,
  IComment,
  ICommentRequest,
  //IReservationRequest,
  IReservation,
  IReservationOuterRequest,
} from './parking.types';

const ROUTES = {
  PARKING: 'parkings',
  ADDRESS: 'address',
  COMMENTS: 'comments',
  RESERVATION: 'reservations',
  USERS: 'users',
};

const getParkingPlaces = (): ApiResponse<IParkingPlaces[]> => {
  return axios.get(`${ROUTES.PARKING}`);
};

const getParking = (id: string): ApiResponse<IParkingPlaces> => {
  return axios.get(`${ROUTES.PARKING}/${id}`);
};

const getParkingWithAddress = (values: IFormAddressToGo): ApiResponse<IParkingPlaces[]> => {
  return axios.get(`${ROUTES.PARKING}/${ROUTES.ADDRESS}`, { params: values });
};

const getComments = (payload: IRequestParkingPlace): ApiResponse<IComment[]> => {
  return axios.get(`${ROUTES.PARKING}/${payload.id}/${ROUTES.COMMENTS}`);
};

const getListOfReservations = (): ApiResponse<IReservation[]> => {
  return axios.get(`${ROUTES.USERS}/${ROUTES.RESERVATION}`);
};

const postComment = (payload: ICommentRequest): ApiResponse<IComment> => {
  return axios.post(`${ROUTES.COMMENTS}`, payload);
};

const postReservation = (payload: IReservationOuterRequest): ApiResponse<IReservation[]> => {
  return axios.post(`${ROUTES.RESERVATION}`, payload);
};

const deleteReservation = (id: string) => {
  return axios.delete(`${ROUTES.RESERVATION}/${id}`);
};

const changeReservation = (payload: IReservationOuterRequest): ApiResponse<IReservation> => {
  return axios.patch(`${ROUTES.RESERVATION}`, payload);
};

export const parkingRepo = {
  getParkingPlaces,
  getParkingWithAddress,
  getComments,
  postComment,
  postReservation,
  getListOfReservations,
  getParking,
  deleteReservation,
  changeReservation,
};
