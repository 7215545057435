import React, { useContext, useEffect } from 'react';
import style from './Header.module.scss';
import { useAuth0 } from '@auth0/auth0-react';
import { LoginButton } from '../../../../../components/LoginButton/LoginButton';
import { ParkingAppContext } from '../../../../../context/parking.context';
import { parkingService } from '../../../../../modules/parking/parking.service';
import to from 'await-to-js';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../../../components/Button/Button';
import { setHeaders } from '../../../../../utils/axios';
import { Hamburger } from '../../../../../assets/icons/Hamburger/Hamburger';
import { toast } from 'react-toastify';

export const Header: React.FC = () => {
  const navigate = useNavigate();

  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const { setParkingAppStateHandler } = useContext(ParkingAppContext);

  useEffect(() => {
    const getAccessToken = async () => {
      try {
        const token = await getAccessTokenSilently();
        setHeaders(token);
        setParkingAppStateHandler({ token: token });
      } catch (error) {
        // console.error('Error getting access token:', error);
      }
    };

    getAccessToken();
  }, [getAccessTokenSilently]);

  const onClick = async () => {
    const [err, res] = await to(parkingService.getListOfReservations());
    if (err || !res) return toast.error('Something went wrong');
    console.log(res, 'reservation');

    setParkingAppStateHandler({ sideBarVisible: true, reservationList: res });
  };

  return (
    <div className={`${style.header} spaceBetween`}>
      <div>
        <div className={style.logo}>
          <p>Park</p>
          <span>Pilot</span>
        </div>
      </div>
      <div className={style.button}>
        {!isAuthenticated ? (
          <LoginButton />
        ) : (
          <div className={style.right}>
            <div>
              <Button
                name="host"
                onClick={() => {
                  setParkingAppStateHandler({ parkingList: [] });
                  navigate('/host');
                }}
              >
                Become Host
              </Button>
            </div>
            <div onClick={onClick}>
              <Hamburger />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
