import React, { useContext, useEffect } from 'react';
import { Button } from '../../../../components/Button/Button';
import { ModalsContext } from '../../../../context/modal.context';
import { NewParkingPlaceModal } from '../../../../components/Modals/NewParkingPlaceModal/NewParkingPlaceModal';
import style from './HostParking.module.scss';
import { hostService } from '../../../../modules/host/host.service';
import to from 'await-to-js';
import { ParkingAppContext } from '../../../../context/parking.context';
import { HostParkingList } from './components/HostParkingList/HostParkingList';
import { BackBtn } from '../../../../assets/icons/BackBtn/BackBtn';
import { useNavigate } from 'react-router';
import { Row } from 'antd';
import { AddUserDataModal } from '../../../../components/Modals/AddUserDataModal/AddUserDataModal';
import { AddDeviceModal } from '../../../../components/Modals/AddDeviceModal/AddDeviceModal';

export const HostParking: React.FC = () => {
  const { setParkingAppStateHandler } = useContext(ParkingAppContext);
  const navigate = useNavigate();
  const loadData = async () => {
    const [err, res] = await to(hostService.getHostParkings());
    if (err || !res) return;
    const newParkingList = hostService.newHostParkingList(res);
    setParkingAppStateHandler({ parkingList: newParkingList });
  };

  useEffect(() => {
    loadData();
  }, []);

  const { handleUpdateModals } = useContext(ModalsContext);

  const openNewParkingPlaceModal = () => {
    handleUpdateModals({ modalVisible: 'new-parking-place' });
  };

  const openAddUserDataModal = () => {
    console.log('clicked user data button');
    handleUpdateModals({ modalVisible: 'add-user-data' });
  };

  return (
    <div style={{ height: '95%', width: '100%' }}>
      <NewParkingPlaceModal />
      <AddUserDataModal />
      <AddDeviceModal />
      <Row justify="space-between">
        <div onClick={() => navigate('/')}>
          <BackBtn />
        </div>
        <div className={style.button}>
          <Button name="new-parking-button" onClick={openNewParkingPlaceModal}>
            Add new parking
          </Button>
          <Button style={{ marginTop: '20px' }} name="user-data-button" onClick={openAddUserDataModal}>
            Add user data
          </Button>
        </div>
      </Row>
      <div style={{ height: '91%' }}>
        <HostParkingList />
      </div>
    </div>
  );
};
