import React, { useContext } from 'react';
import style from './ListOfPlaces.module.scss';
import { ParkingAppContext } from '../../../../../../../context/parking.context';
import { ListItem } from './components/ListItem/ListItem';
import { IParkingPlaces } from '../../../../../../../modules/parking/parking.types';

export const ListOfPlaces = () => {
  const { parkingList } = useContext(ParkingAppContext);

  return (
    <div className={style.scrollableList}>
      {parkingList.map((place: IParkingPlaces, index: number) => (
        <ListItem place={place} key={index} index={index} />
      ))}
    </div>
  );
};
