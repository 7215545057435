import React, { useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import style from './SidebarComponent.module.scss';
import ListOfReservation from './NavList/components/ListOfReservation/ListOfReservation';
import { LogoutButton } from '../../../../../../../components/LogoutButton/LogoutButton';
import { ParkingAppContext } from '../../../../../../../context/parking.context';
import { CloseButton } from '../../../../../../../components/CloseButton/CloseButton';
import { Contact } from './NavList/components/Contact/Contact';
import { InfoUser } from './InfoUser/InfoUser';
import { NavList } from './NavList/NavList';

export const SidebarComponent = () => {
  const sidebarmenu = [
    {
      title: 'List of reservation',
      content: <ListOfReservation />,
    },
    {
      title: 'Contact',
      content: <Contact />,
    },
  ];

  const { user, isAuthenticated } = useAuth0();
  const { setParkingAppStateHandler } = useContext(ParkingAppContext);

  const closeSidebar = () => {
    setParkingAppStateHandler({ sideBarVisible: false });
  };

  return (
    <>
      {isAuthenticated && user !== undefined ? (
        <div className={style.wrapper}>
          <div className={style.closeButton}>
            <CloseButton onClick={closeSidebar} />
          </div>
          <InfoUser />
          <NavList sidebarmenu={sidebarmenu} />
          <div className={style.buttonFaq}>
            <LogoutButton />
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
};
