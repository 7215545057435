import style from './FormAddDevice.module.scss';
import { Button, Form } from 'antd';
import React, { useContext, useState } from 'react';
import { FormInput } from '../../../../FormInput/FormInput';
import { hostService } from '../../../../../modules/host/host.service';
import { toast } from 'react-toastify';
import { ParkingAppContext } from '../../../../../context/parking.context';
import { ModalsContext } from '../../../../../context/modal.context';

const INITIAL_DEVICE_DATA = {
  securityKey: '',
  authKey: '',
  ip: '',
};

export const FormAddDevice: React.FC = () => {
  const [form] = Form.useForm();
  const { clearModals } = useContext(ModalsContext);
  const { setParkingAppStateHandler, currentParkingId, parkingList } = useContext(ParkingAppContext);
  const [submitButtonType, setSubmitButtonType] = useState('');
  const currentParking = parkingList.filter((parking: any) => parking.uuid === currentParkingId);
  console.log('current parking:', currentParking);
  console.log(submitButtonType);

  console.log('Current Parking Id (FormAddDevice.tsx)', currentParkingId);

  const onSubmit = async (values: any) => {
    const payload = {
      uuid: currentParkingId,
      ...values,
    };

    toast.success('You have successfully add device');
    //console.log(payload);
    setParkingAppStateHandler({ device_data: payload });

    const fetchData = async () => {
      try {
        const res = await hostService.postDevice(payload);
        console.log(res);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();

    //const parkingWithPatchedDevicePresent = { ...currentParking, devicePresent: !currentParking.devicePresent };
    //console.log('Parking With Patched Device Present:', parkingWithPatchedDevicePresent);
    //console.log('New devicePresent:', parkingWithPatchedDevicePresent.devicePresent);

    clearModals();
    form.resetFields();
  };

  return (
    <Form
      form={form}
      className={style.form}
      name="add-device-form"
      initialValues={INITIAL_DEVICE_DATA}
      onFinish={(values: any) => onSubmit(values)}
      layout="vertical"
    >
      <div className={style.formInput}>
        <div className={style.left}>
          <FormInput
            placeholder="Security Key"
            name="securityKey"
            rules={[{ required: true }]}
            label="Security Key"
            inputName="securityKey"
            type="text"
          />
          <FormInput
            placeholder="Auth Key"
            rules={[{ required: true }]}
            name="authKey"
            label="Auth Key"
            inputName="authKey"
            type="text"
          />
          <FormInput
            placeholder="IP Address"
            rules={[{ required: true }]}
            name="ip"
            label="IP Address"
            inputName="ip"
            type="text"
          />
        </div>
      </div>

      <Form.Item className={style.button}>
        <Button htmlType="submit" onClick={() => setSubmitButtonType('submit')}>
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};
