import React from 'react';
import { AddressForm } from './components/AddressForm/AddressForm';
import Map from './components/Map/Map';
import { useLoadScript } from '@react-google-maps/api';
import { ListOfPlaces } from './components/ListOfPlaces/ListOfPlaces';
import style from './Main.module.scss';

export const Main = () => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyDL10PeKlYypKk_h8vyCXvn160uCR4FebM',
    libraries: ['places'],
  });

  if (!isLoaded) {
    return <div>Somthing went wrong...</div>;
  }

  return (
    <div className={style.main}>
      <div className={style.left}>
        <AddressForm />
        <ListOfPlaces />
      </div>
      <Map />
    </div>
  );
};
