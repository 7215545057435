import React, { useContext } from 'react';
import style from './ParkingInfo.module.scss';
import { ClockCircleOutlined, DollarOutlined } from '@ant-design/icons';
import { ParkingAppContext } from '../../../../../../../../../context/parking.context';
import { WorkingHours } from '../../../../../../../../../components/WorkingHours/WorkingHours';
import LocationIcon from '../../../../../../../../../assets/icons/LocationIcon/LocationIcon';
import { Col } from 'antd';
import { Button } from '../../../../../../../../../components/Button/Button';

const ParkingInfo: React.FC = () => {
  const { parkingPlace } = useContext(ParkingAppContext);
  const redirectToGoogleMaps = () => {
    const mapsURL = `https://www.google.com/maps?q=${parkingPlace.latitude},${parkingPlace.longitude}`;
    window.open(mapsURL, '_blank');
  };
  console.log(parkingPlace, 'parkingPlace');

  return (
    <div className={style.mainInfo}>
      <div className={style.name}>{parkingPlace.name}</div>
      <Col className={style.col}>
        <div className={style.icons}>
          <LocationIcon />
        </div>
        <div>{parkingPlace.address}</div>
      </Col>

      <Col className={style.col}>
        <div className={style.icons}>
          <DollarOutlined />
        </div>
        <div>{parkingPlace.price} rsd / h</div>
      </Col>

      <Col className={style.col}>
        <div className={style.icons}>
          <ClockCircleOutlined />
        </div>
        <WorkingHours />
      </Col>

      <div className={style.button}>
        <Button name="direction" onClick={redirectToGoogleMaps}>
          Go to direction {'>'}
          {'>'}
          {'>'}
        </Button>
      </div>
    </div>
  );
};

export default ParkingInfo;
