import React, { useContext, useEffect, useState } from 'react';
import { Modal } from '../../Modal/Modal';
import { ModalsContext } from '../../../context/modal.context';
import style from './ReservationModal.module.scss';
import { DateTimeDurationForm } from '../../DateTimeDurationForm/DateTimeDurationForm';
import { ParkingAppContext } from '../../../context/parking.context';
import { Button, Col, Form, Row, Select } from 'antd';
import ParkingInfo from '../../../pages/Customer/HomePage/components/Main/components/InfoParkingModal/components/ParkingInfo/ParkingInfo';
import { ReservationDeleteChangeBtn } from './components/ReservationDeleteChangeBtn/ReservationDeleteChangeBtn';
import { IReservationRequest } from '../../../modules/parking/parking.types';
import { useNavigate } from 'react-router';
import { CloseButton } from '../../CloseButton/CloseButton';
import ImageComponent from '../../ImageComponent/ImageComponent';

interface IReservationProps {
  onOk?: () => any;
  onCancel?: () => any;
}

export const ReservationModal: React.FC<IReservationProps> = () => {
  const { isModalVisible, clearModals, modals } = useContext(ModalsContext);

  const { parkingPlace, timeDateDuration, card_number } = useContext(ParkingAppContext);
  const [reservation, setReservation] = useState<IReservationRequest>({
    parkingId: parkingPlace.uuid,
    uuid: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    guestId: '4fa85f64-5717-4562-b3fc-2c963f66afa6',
    duration: 1,
    start: null,
    cardNumber: '',
    price: 0,
  });
  const navigate = useNavigate();

  const addCard = () => {
    navigate('/payment');
  };

  useEffect(() => {
    if (parkingPlace.uuid) {
      setReservation({ ...reservation, parkingId: parkingPlace.uuid });
    }
  }, [parkingPlace.uuid]);

  useEffect(() => {
    setReservation({
      ...reservation,
      duration: timeDateDuration.duration,
      start: timeDateDuration.start,
    });
  }, [timeDateDuration]);

  return (
    <Modal
      name="reservation-parking-modal"
      closable={false}
      open={isModalVisible('reservation-parking-modal')}
      className={`.and-modal-wrap .ant-modal`}
      style={{ fontWeight: 'bold' }}
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ style: { display: 'none' } }}
      width={'500px'}
    >
      <Form layout="vertical" name="reservation-form">
        <div>
          <div
            style={{
              position: 'absolute',
              right: '10px',
              top: '10px',
            }}
          >
            <CloseButton onClick={() => clearModals()} />
          </div>

          <div>
            <div className={style.img}>
              <ImageComponent imageUrl={`data:image/png;base64,${parkingPlace.image}`} />
            </div>
            <div
              style={{
                position: 'absolute',
                left: '0px',
                top: '0px',
                display: 'flex',
              }}
            >
              <ParkingInfo />
            </div>
          </div>
          <div className={style.main}>
            <div className={style.left}></div>
            <div className={style.right}>
              {modals.modalType === 'reservation' && (
                <Row justify={'space-between'}>
                  <Col>Payment card: </Col>
                  <Col span={12}>
                    <Select
                      onChange={(_, option: any) => {
                        const cardNumber = typeof option === 'string' ? option : '';
                        setReservation({ ...reservation, cardNumber: cardNumber });
                      }}
                    >
                      {card_number.map(card => {
                        return <Select.Option key={card} value={card}>{`**** **** **** ${card}`}</Select.Option>;
                      })}
                    </Select>
                  </Col>

                  <Col>
                    <Button name="add-card" onClick={addCard}>
                      Add Card
                    </Button>
                  </Col>
                </Row>
              )}
              <DateTimeDurationForm state={reservation} setState={setReservation} />
              <ReservationDeleteChangeBtn payload={reservation} />
            </div>
          </div>
        </div>
      </Form>
    </Modal>
  );
};
