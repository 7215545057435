import { useContext, useEffect } from 'react';
import { INITIAL_PARKING_PLACE, ParkingAppContext } from '../../../context/parking.context';
import { Header } from './components/Header/Header';
import { Main } from './components/Main/Main';
import style from './HomePage.module.scss';
import to from 'await-to-js';
import SideBar from './components/SideBar/SideBar';
import { parkingService } from '../../../modules/parking/parking.service';
import { useDebouncedCallback } from 'use-debounce';
import { ReservationModal } from '../../../components/Modals/ReservationModal/ReservationModal';
import { Toast } from '../../../components/Toast/Toast';
import { QrCodeModal } from '../../../components/Modals/QrCodeModal/QrCodeModal';

export const HomePage = () => {
  const { setParkingAppStateHandler } = useContext(ParkingAppContext);

  const loadData = useDebouncedCallback(async () => {
    const [err, res] = await to(parkingService.getParkingPlaces());
    if (err || !res) return;

    setParkingAppStateHandler({ parkingList: res, parkingPlace: res[0] ? res[0] : INITIAL_PARKING_PLACE });
  }, 400);

  useEffect(() => {
    loadData();
  }, []);

  return (
    <SideBar>
      <ReservationModal />
      <QrCodeModal />
      <div className={` ${style.home} `}>
        <Header />
        <Main />
      </div>
      <Toast />
    </SideBar>
  );
};
