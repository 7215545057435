import React from 'react';
import { IWorkingHours } from '../../../../modules/parking/parking.types';
import style from './WorkingHoursListItem.module.scss';
interface IWorkingHoursProps {
  item: IWorkingHours;
}

export const WorkingHoursListItem: React.FC<IWorkingHoursProps> = props => {
  return (
    <div className={style.day}>
      <span>{props.item.dayInAWeek}:</span>
      <div>
        <span>{props.item.openTime}h</span>-<span>{props.item.closeTime}h</span>
      </div>
    </div>
  );
};
