import React, { ReactElement, useState } from 'react';
import style from './NavListItem.module.scss';
interface INavListProps {
  navItem: INavList;
}

interface INavList {
  title: string;
  content: ReactElement;
}

const NavListItem: React.FC<INavListProps> = props => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className={style.itemWrapper}>
      <div
        className={style.itemName}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        {props.navItem.title}
      </div>
      {isOpen && <div>{props.navItem.content}</div>}
    </div>
  );
};

export default NavListItem;
