import style from './FormNewParking.module.scss';
import { Button, Col, Form, InputNumber, Modal, Row, Switch } from 'antd';
import React, { useContext, useState } from 'react';
import { FormInput } from '../../../../FormInput/FormInput';
import FormItem from 'antd/es/form/FormItem';
import to from 'await-to-js';
import { hostService } from '../../../../../modules/host/host.service';
import { IParkingPlaces } from '../../../../../modules/parking/parking.types';
import { ModalsContext } from '../../../../../context/modal.context';

import { ImageUpload } from '../../../../ImageUpload/ImageUpload';
import { DayPickerNewParking } from '../DayPickerNewParking/DayPickerNewParking';
import { toast } from 'react-toastify';
import { ParkingAppContext } from '../../../../../context/parking.context';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const INITIAL_NEW_PARKING = {
  address: '',
  basePrice: null,
  name: '',
  profileImage: null,
  start1: '',
  start2: '',
  start3: '',
  start4: '',
  start5: '',
  start6: '',
  start7: '',
};

interface IFormProps {
  data: boolean;
  place?: IParkingPlaces;
}

export const FormNewParking: React.FC<IFormProps> = props => {
  const [form] = Form.useForm();
  const { clearModals, handleUpdateModals } = useContext(ModalsContext);
  const { setParkingAppStateHandler, parkingList, currentParkingId } = useContext(ParkingAppContext);
  const [submitButtonType, setSubmitButtonType] = useState('');
  console.log(props.place, 'place');
  console.log('devicePresent:', props.place?.devicePresent);
  // const { handleUpdateModals } = useContext(ModalsContext);

  const onChange = (checked: boolean) => {
    //console.log(`switch to ${checked}`);
    const payloadDeviceCommand = (command: string) => {
      hostService.postDeviceCommander({
        command: command,
        parkingId: props.place?.uuid,
      });
    };
    checked ? payloadDeviceCommand('OPEN') : payloadDeviceCommand('CLOSE');
  };

  const openAddDeviceModal = () => {
    console.log('Clicked Add Device Button');
    console.log('uuid:', props.place?.uuid);
    const newParkingId = props.place?.uuid;
    setParkingAppStateHandler({ currentParkingId: newParkingId });
    console.log('Current Parking Id (FormNewParking.tsx)', currentParkingId);
    console.log('props of FormNewParking.tsx', props);
    handleUpdateModals({ modalVisible: 'add-device-data' });
  };

  if (props.data) {
    form.setFieldsValue({
      address: props.place?.address,
      basePrice: props.place?.price,
      name: props.place?.name,
      profileImage: null,
      ...hostService.formatTimeRanges(props.place?.workingHours ? props.place?.workingHours : []),
    });
  }

  const patchParking = async (values: IParkingPlaces) => {
    console.log(values, 'value');

    const [err, res] = await to(hostService.patchParking(values));
    if (err || !res) return toast.error('Something went wrong');
  };

  const deleteParking = async () => {
    Modal.confirm({
      title: 'Delete parking place',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure that you want to delete parking place?',
      okText: 'Yes',
      style: { padding: '10px' },
      cancelText: 'Cancel',
      onOk: async () => {
        const [err, res] = await to(hostService.deleteParking(props.place?.uuid ? props.place?.uuid : ''));
        if (err || !res) return toast.error('Something went wrong');
        toast.success('You have successfully delete parking place');
        const newParkingList = parkingList.filter((item: any) => item.uuid !== props.place?.uuid);
        setParkingAppStateHandler({ parkingList: newParkingList });
      },
    });
  };

  const getNewParking = async () => {
    const [err, res] = await to(hostService.getHostParkings());
    if (err || !res) return;
    const newParkingList = hostService.newHostParkingList(res);
    setParkingAppStateHandler({ parkingList: newParkingList });
  };

  const onSubmit = async (values: any) => {
    if (submitButtonType === 'delete') {
      return deleteParking();
    }

    if (submitButtonType === 'change') {
      return patchParking({ ...values, id: props.place?.uuid });
    }

    const formData = new FormData();
    await formData.append('profileImage', values.profileImage.file);

    const payload = {
      ...values,
      profileImage: formData,
    };
    const [err, res] = await to<any>(hostService.postNewParking(payload));
    if (err || !res) return toast.error('Something went wrong');
    getNewParking();
    toast.success('You have successfully made a new parking');

    clearModals();
    form.resetFields();
  };

  return (
    <Form
      form={form}
      className={style.form}
      name="new-parking-form"
      initialValues={INITIAL_NEW_PARKING}
      onFinish={(values: any) => onSubmit(values)}
      layout="vertical"
    >
      <div className={style.formInput}>
        <div className={style.left}>
          {!props.data ? (
            <div>
              <label>Image upload:</label>
              <ImageUpload />
            </div>
          ) : (
            <div className={style.img}>
              <img src={`data:image/png;base64,${props.place?.image}`} alt="parking" />
            </div>
          )}
          <FormInput
            placeholder="Address"
            rules={[{ required: true }]}
            name="address"
            label="Address"
            inputName="address"
            type="text"
          />
          <FormInput
            placeholder="Name"
            name="name"
            rules={[{ required: true }]}
            label="Name"
            inputName="name"
            type="text"
          />
          <FormItem name="basePrice" label="Price:" rules={[{ required: true }]}>
            <InputNumber placeholder="Price" name="price" step={50} min={100} max={500} />
          </FormItem>

          {props.data && props.place?.devicePresent && (
            <Switch style={{ width: '10%' }} defaultChecked={false} onChange={onChange} />
          )}
        </div>
        <div className={style.right}>
          <label>Time per day:</label>
          <DayPickerNewParking />
        </div>
      </div>
      {!props.data ? (
        <Form.Item className={style.button}>
          <Button htmlType="submit" onClick={() => setSubmitButtonType('submit')}>
            Add New Parking
          </Button>
        </Form.Item>
      ) : (
        <Row justify={'end'} gutter={10}>
          <Col>
            <Form.Item className={style.button}>
              <Button disabled={props.place?.devicePresent} onClick={openAddDeviceModal}>
                Add Device
              </Button>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item className={style.button}>
              <Button htmlType="submit" onClick={() => setSubmitButtonType('change')}>
                Change
              </Button>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item>
              <Button danger htmlType="submit" onClick={() => setSubmitButtonType('delete')}>
                Delete
              </Button>
            </Form.Item>
          </Col>
        </Row>
      )}
    </Form>
  );
};
