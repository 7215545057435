import React from 'react';
import style from './DayPickerNewParking.module.scss';
import { TimePicker, Form } from 'antd';

export const DayPickerNewParking: React.FC = () => {
  const handleFocus = (e: any) => {
    if (window.innerWidth < 768) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  return (
    <div className={style.dayPickerWrapper} id="time-per-day">
      <Form.Item label="Monday:" name="start1" className={style.day} rules={[{ required: true }]}>
        <TimePicker.RangePicker format="HH a" onFocus={handleFocus} />
      </Form.Item>
      <Form.Item label="Tuesday:" name="start2" className={style.day} rules={[{ required: true }]}>
        <TimePicker.RangePicker format="HH a" onFocus={handleFocus} />
      </Form.Item>
      <Form.Item label="Wednesday:" name="start3" className={style.day} rules={[{ required: true }]}>
        <TimePicker.RangePicker format="HH a" onFocus={handleFocus} />
      </Form.Item>
      <Form.Item label="Thursday:" name="start4" className={style.day} rules={[{ required: true }]}>
        <TimePicker.RangePicker format="HH a" onFocus={handleFocus} />
      </Form.Item>
      <Form.Item label="Friday:" name="start5" className={style.day} rules={[{ required: true }]}>
        <TimePicker.RangePicker format="HH a" onFocus={handleFocus} />
      </Form.Item>
      <Form.Item label="Saturday:" name="start6" className={style.day} rules={[{ required: true }]}>
        <TimePicker.RangePicker format="HH a" onFocus={handleFocus} />
      </Form.Item>
      <Form.Item label="Sunday:" name="start7" className={style.day} rules={[{ required: true }]}>
        <TimePicker.RangePicker format="HH a" onFocus={handleFocus} />
      </Form.Item>
    </div>
  );
};
