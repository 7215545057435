import React, { useContext } from 'react';

import style from './ListOfReservation.module.scss';
import { ReservationItem } from './ReservationItem/ReservationItem';
import { ParkingAppContext } from '../../../../../../../../../../context/parking.context';
export const ListOfReservation: React.FC = () => {
  const { reservationList } = useContext(ParkingAppContext);
  return (
    <div className={style.wrapperList}>
      <div className={style.scrollableList}>
        {reservationList.map((place, index) => (
          <ReservationItem place={place} key={index} index={index} />
        ))}
      </div>
    </div>
  );
};

export default ListOfReservation;
