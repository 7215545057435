import React, { useContext } from 'react';

import { Modal } from '../../Modal/Modal';
import { ModalsContext } from '../../../context/modal.context';

interface IInfoModalProps {
  title?: string;
  message: string;
  onOk?: () => any;
  onCancel?: () => any;
}

export const InfoModal: React.FC<IInfoModalProps> = ({ title, message, onCancel, onOk }) => {
  const { isModalVisible, clearModals } = useContext(ModalsContext);

  const handleCancel = () => {
    onCancel && onCancel();
    clearModals();
  };

  const handleOk = () => {
    onOk && onOk();
    clearModals();
  };

  return (
    <Modal
      name="obavestenje"
      title={title}
      mask={true}
      onCancel={handleCancel}
      cancelText="Назад"
      onOk={handleOk}
      okButtonProps={{ style: { display: 'none' } }}
      open={isModalVisible('info')}
    >
      <div>{message}</div>
    </Modal>
  );
};
