import React from 'react';
import { InboxOutlined } from '@ant-design/icons';
import { UploadProps, message, Upload, Form } from 'antd';
// import { parkingService } parkingService '../../modules/parking/parking.service';

const { Dragger } = Upload;

const props: UploadProps = {
  name: 'profileImage',
  multiple: true,
  action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
  beforeUpload() {
    return false;
  },
  async onChange(info) {
    const file = info.file;
    const { status } = info.file;
    if (status !== 'uploading') {
      // console.log(info.file, info.fileList, 'uploading');
    }
    if (status === 'done') {
      return file;
    } else if (status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};

export const ImageUpload: React.FC = () => {
  return (
    <Form.Item valuePropName="profileImage" name="profileImage">
      <Dragger {...props} beforeUpload={() => false}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Click or drag file to this area to upload</p>
      </Dragger>
    </Form.Item>
  );
};
