import React, { useContext, useEffect } from 'react';
import style from './InfoParkingModal.module.scss';
import { ModalsContext } from '../../../../../../../context/modal.context';
import { Button } from '../../../../../../../components/Button/Button';
import { useAuth0 } from '@auth0/auth0-react';
import ParkingInfo from './components/ParkingInfo/ParkingInfo';
import { Footer } from 'antd/es/layout/layout';
import { Comment } from './components/Comment/Comment';
import { ParkingAppContext } from '../../../../../../../context/parking.context';
import { CloseButton } from '../../../../../../../components/CloseButton/CloseButton';
import { parkingService } from '../../../../../../../modules/parking/parking.service';
import to from 'await-to-js';
import axios from 'axios';
import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router';
import ImageComponent from '../../../../../../../components/ImageComponent/ImageComponent';

interface IResponseCard {
  user_cards: string[];
}

export const InfoParkingModal: React.FC = () => {
  const { handleUpdateModals } = useContext(ModalsContext);
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const { setParkingAppStateHandler, parkingPlace, token } = useContext(ParkingAppContext);
  const navigate = useNavigate();
  const fetchCard = async () => {
    try {
      const response = await axios.get<IResponseCard>(`https://parkpilot.ai/api/payment/user?token=${token}`);
      if (!isEmpty(response.data.user_cards)) {
        return setParkingAppStateHandler({ card_number: response.data.user_cards });
      }
      navigate('/payment');
    } catch (err) {
      throw new Error(`Error fetching data`);
    }
  };

  const openReservationModal = async () => {
    if (!isAuthenticated) {
      return loginWithRedirect();
    }
    fetchCard();
    handleUpdateModals({ modalVisible: 'reservation-parking-modal', modalType: 'reservation' });
  };

  const getComment = async () => {
    const [err, res] = await to(parkingService.getComments({ id: parkingPlace.uuid }));
    if (err || !res) return;
    setParkingAppStateHandler({ commentList: res });
  };

  useEffect(() => {
    getComment();
  }, [parkingPlace]);

  const closeInfoParkingModal = () => {
    setParkingAppStateHandler({ isInfoParkingVisible: false });
  };

  return (
    <>
      <div className={style.wrapperInfo}>
        <div className={style.infoParking}>
          <div className={style.closeButton}>
            <CloseButton onClick={closeInfoParkingModal} />
          </div>
          <div className={style.img}>
            <ImageComponent imageUrl={`data:image/png;base64,${parkingPlace.image}`} />
          </div>
          <ParkingInfo />
          <Comment />
          <Footer className={style.footer}>
            <Button name="reservation" onClick={openReservationModal}>
              Reservation
            </Button>
          </Footer>
        </div>
      </div>
    </>
  );
};
