import { createContext, useState } from 'react';
import {
  IAddDevice,
  IComment,
  IParkingPlaces,
  IReservation,
  ITimeDateDuration,
  IUserData,
  IWorkingHours,
} from '../modules/parking/parking.types';
import dayjs from 'dayjs';

interface IParkingAppContext extends IParkingAppState {
  parkingAppState: IParkingAppState;
  setParkingAppStateHandler: (params: Partial<IParkingAppState>) => void;
}

const INITIAL_WORKING_HOURS = [
  {
    dayInAWeek: 0,
    openTime: 0,
    closeTime: 0,
  },
];

export const INITIAL_PARKING_PLACE = {
  uuid: '',
  name: '',
  address: '',
  price: 0,
  latitude: 0,
  longitude: 0,
  imageUrl: '',
  image: [''],
  //startTime: 0,
  //endTime: 0,
  userId: '',
  workingHours: INITIAL_WORKING_HOURS,
  devicePresent: false,
};

const INITIAL_COMMENT = {
  uuid: '',
  userName: '',
  parkingUuid: '',
  body: '',
};

const INITIAL_RESERVATION = {
  reservationUuid: '',
  start: 0,
  duration: 1,
  status: '',
  parkingResponse: {
    price: 0,
    latitude: 0,
    longitude: 0,
    imageUrl: '',
    image: '',
    uuid: '',
    userId: '',
    name: '',
    address: '',
    workingHours: INITIAL_WORKING_HOURS,
    devicePresent: false,
  },
  customerResponse: {
    firstName: '',
    lastName: '',
    email: '',
    uuid: '',
  },
  paymentResponse: {
    paymentStatus: '',
    price: 0.0,
  },
};
// Test
const INITIAL_TIME_DATE_DURATION = {
  start: dayjs(),
  duration: 1,
};

export const INITIAL_PLACE_TO_GO = {
  address: '',
  ...INITIAL_TIME_DATE_DURATION,
};

// Host data initial const
const INITIAL_HOST_DATA = {
  firstName: '',
  lastName: '',
  email: '',
  bankAccount: '',
};

const INITIAL_DEVICE_DATA = {
  parkingId: '',
  securityKey: '',
  authKey: '',
  ip: '',
};

export interface IParkingAppState {
  parkingList: IParkingPlaces[] | any;
  parkingPlace: IParkingPlaces;
  workingHours: IWorkingHours[];
  isInfoParkingVisible: boolean;
  sideBarVisible: boolean;
  commentList: IComment[];
  comment: IComment;
  reservationList: IReservation[];
  reservation: IReservation;
  timeDateDuration: ITimeDateDuration;
  token: string;
  card_number: string[];
  host_data: IUserData;
  device_data: IAddDevice;
  currentParkingId: string;
}

const initialParkingAppState = {
  parkingList: [],
  parkingPlace: INITIAL_PARKING_PLACE,
  workingHours: INITIAL_WORKING_HOURS,
  isInfoParkingVisible: false,
  sideBarVisible: false,
  commentList: [],
  comment: INITIAL_COMMENT,
  reservationList: [],
  reservation: INITIAL_RESERVATION,
  timeDateDuration: INITIAL_TIME_DATE_DURATION,
  token: '',
  card_number: [],
  host_data: INITIAL_HOST_DATA,
  device_data: INITIAL_DEVICE_DATA,
  currentParkingId: '',
};

export const ParkingAppContext = createContext<IParkingAppContext>(null as any);

export const ParkingAppProvider = ({ children }: { children: any }) => {
  const [parkingAppState, setParkingAppState] = useState<IParkingAppState>(initialParkingAppState);

  const setParkingAppStateHandler = (valueToUpdate: Partial<IParkingAppState>) => {
    setParkingAppState(prev => ({ ...prev, ...valueToUpdate }));
  };

  return (
    <ParkingAppContext.Provider
      value={{
        parkingAppState: parkingAppState,
        parkingList: parkingAppState.parkingList,
        parkingPlace: parkingAppState.parkingPlace,
        workingHours: parkingAppState.workingHours,
        isInfoParkingVisible: parkingAppState.isInfoParkingVisible,
        sideBarVisible: parkingAppState.sideBarVisible,
        commentList: parkingAppState.commentList,
        comment: parkingAppState.comment,
        reservationList: parkingAppState.reservationList,
        reservation: parkingAppState.reservation,
        timeDateDuration: parkingAppState.timeDateDuration,
        token: parkingAppState.token,
        card_number: parkingAppState.card_number,
        host_data: parkingAppState.host_data,
        device_data: parkingAppState.device_data,
        currentParkingId: parkingAppState.currentParkingId,
        setParkingAppStateHandler,
      }}
    >
      {children}
    </ParkingAppContext.Provider>
  );
};
