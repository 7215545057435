import React from 'react';
import styles from './HostHomePage.module.scss';
import { HostParking } from './components/HostParking/HostParking';

export const HostHomePage: React.FC = () => {
  return (
    <div className={styles.wrapper}>
      <HostParking />
    </div>
  );
};
