import React, { useContext } from 'react';
import { ParkingAppContext } from '../../../../../../../../../../../context/parking.context';
import style from './CommentList.module.scss';
import { IComment } from '../../../../../../../../../../../modules/parking/parking.types';
export const CommentList: React.FC = () => {
  const { commentList } = useContext(ParkingAppContext);
  return (
    <div className={style.commentList}>
      <div>List of comments : </div>
      {commentList &&
        commentList.map((item: IComment, index: number) => {
          return (
            <div className={style.listItem} key={index}>
              <span>{item.userName}</span>
              <div>{item.body}</div>
            </div>
          );
        })}
    </div>
  );
};
