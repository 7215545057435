import { Modal } from '../../Modal/Modal';
import { useContext } from 'react';
import { ModalsContext } from '../../../context/modal.context';

export const QrCodeModal: React.FC = () => {
  const { isModalVisible, modals, clearModals } = useContext(ModalsContext);
  return (
    <Modal
      name="qr-code-modal"
      // closable={false}
      open={isModalVisible('qr-code-modal')}
      className={`.and-modal-wrap .ant-modal`}
      style={{ padding: '20px', display: 'flex', fontWeight: 'bold' }}
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ style: { display: 'none' } }}
      onCancel={clearModals}
      title={'Qr Code reservation'}
    >
      {modals.modalData}
    </Modal>
  );
};
