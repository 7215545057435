// import React, { useContext, useState } from 'react';
import style from './ReservationItem.module.scss';
import { IReservation } from '../../../../../../../../../../../modules/parking/parking.types';
import { EditIcon } from '../../../../../../../../../../../assets/icons/EditIcon/EditIcon';
import { useContext } from 'react';
import { ModalsContext } from '../../../../../../../../../../../context/modal.context';
import { ParkingAppContext } from '../../../../../../../../../../../context/parking.context';
import to from 'await-to-js';
import { parkingService } from '../../../../../../../../../../../modules/parking/parking.service';
import { Col, Row, Switch } from 'antd';
import QRCode from 'react-qr-code';
import queryString from 'query-string';
import { hostService } from '../../../../../../../../../../../modules/host/host.service';
import dayjs from 'dayjs';

interface ItemProps {
  place: IReservation;
  index: number;
}

export const ReservationItem: React.FC<ItemProps> = props => {
  const { handleUpdateModals } = useContext(ModalsContext);
  const { setParkingAppStateHandler, reservationList, token } = useContext(ParkingAppContext);

  const startTime = props.place?.start * 1000; // unix timestamp in seconds * 1000 = miliseconds
  console.log(startTime);
  const startDate = new Date(startTime);
  console.log(startDate);
  console.log(startDate.toLocaleDateString());

  const isSwitchPresent = () => {
    const time = new Date();
    const currentTime = time.getTime();
    const duration = props.place.duration;
    const durationTime = duration * 60 * 60 * 1000;
    const endTime = startTime + durationTime;
    const isCurrentTimeInRange = currentTime >= startTime && currentTime < endTime;
    return (
      isCurrentTimeInRange &&
      props.place.paymentResponse.paymentStatus === 'COMPLETED' &&
      props.place.parkingResponse.devicePresent
    );
  };

  console.log(isSwitchPresent());

  const toggleGate = (checked: boolean) => {
    const payloadDeviceCommand = async (command: string) => {
      try {
        const res = await hostService.postDeviceCommander({
          command: command,
          parkingId: props.place?.parkingResponse.uuid,
        });
        console.log(res);
      } catch (error) {
        console.error(error);
      }
    };
    checked ? payloadDeviceCommand('OPEN') : payloadDeviceCommand('CLOSE');
  };

  const openReservationModal = async () => {
    const [err, res] = await to(parkingService.getParking(props.place.parkingResponse.uuid));
    if (err || !res) return;
    //const dayjs = parkingService.formatToString(startDate.toString());
    //const dayjs = dayjs(startDate.toString());
    //const dayjs = startDate.toLocaleDateString();

    setParkingAppStateHandler({
      timeDateDuration: { start: dayjs(startDate.toString()), duration: props.place.duration },
      parkingPlace: res,
      reservation: reservationList.find(item => item.reservationUuid === props.place.reservationUuid),
    });
    handleUpdateModals({ modalVisible: 'reservation-parking-modal', modalType: 'change' });
  };

  const query = queryString.stringify({
    token: token,
    amount: '150',
    duration: props.place.duration,
    reservation_id: props.place.reservationUuid,
    //card_number: props.place.cardNumber,
    date: props.place.start,
  });

  const qrCodeUrl = `https://parkpilot.ai/api/payment/reservation?${query}`;

  const openQrCodeModal = () => {
    handleUpdateModals({
      modalVisible: 'qr-code-modal',
      modalData: (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '50px' }}>
          <QRCode
            value={qrCodeUrl}
            style={{ width: '300px', height: '300px', borderRadius: '10px', padding: '20px' }}
          />
        </div>
      ),
    });
  };

  return (
    <div className={style.wrapper}>
      <div className={`${style.item} `} key={props.index} id={props.place.reservationUuid}>
        <div className={`${style.nameWrapper}`}>
          <div className={style.name}>{props.place.parkingResponse.name}</div>
          <div className={style.editBtn} onClick={openReservationModal}>
            <EditIcon />
          </div>
        </div>
        <Row gutter={6} style={{ alignItems: 'center' }}>
          <Col span={8} style={{ padding: '10px' }} onClick={openQrCodeModal}>
            <QRCode value={qrCodeUrl} style={{ width: '80px', height: '80px', borderRadius: '10px' }} />
          </Col>
          <Col span={16}>
            <div className={style.info}>
              <label>Date:</label>
              <span>
                {startDate.toLocaleDateString('sr-SR', {
                  day: 'numeric',
                  month: '2-digit',
                  year: 'numeric',
                })}
              </span>
            </div>
            <div className={style.info}>
              <label>Time:</label>
              <span>
                {startDate.toLocaleTimeString('sr-SR', {
                  hour: '2-digit',
                  minute: '2-digit',
                })}
              </span>
            </div>
            <div className={style.info}>
              <label>Duration:</label>
              <span>{props.place.duration} h</span>
            </div>
            {isSwitchPresent() && <Switch defaultChecked={false} onChange={toggleGate} />}
          </Col>
        </Row>
      </div>
    </div>
  );
};
